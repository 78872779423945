<template>
  <v-list-item v-if="item" class="list-item px-0 pb-3">
    <v-row no-gutters class="align-center">
      <v-col :cols="item.product.warehousePromo ? 10 : 12" sm="5">
        <v-row no-gutters>
          <v-list-item-avatar width="80" height="80" rounded="0">
            <img
              :src="item.product.mediaURL"
              :alt="item.product.name"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </v-list-item-avatar>

          <v-list-item-content class="default--text pa-0 pa-sm-2">
            <div class="font-weight-bold text-body-2">
              {{ item.product.name }}
            </div>
            <div class="text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </div>
          </v-list-item-content>
        </v-row>
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.xs && item.product.warehousePromo ? 2 : 0"
        sm="1"
        class="d-flex justify-end"
      >
        <ProductPromo
          :warehousePromo="{
            view: { body: 'Promo', cssClass: 'order_detail' }
          }"
          v-if="hasPromo(item)"
        ></ProductPromo>
      </v-col>
      <v-col
        cols="2"
        sm="2"
        class="justify-center justify-sm-left"
        v-if="isActive"
      >
        <ProductPrice
          :product="item.product"
          :showStandard="false"
        ></ProductPrice
      ></v-col>
      <v-col cols="1" class="d-none" :class="{ 'd-md-flex': isActive }" />
      <v-col
        cols="9"
        :sm="isActive ? 3 : 5"
        :md="isActive ? 2 : 5"
        class="d-flex w-100 justify-space-around justify-sm-left"
      >
        <template v-if="isActive && item.product.priceDisplay > 0">
          <div
            class="qty-wrap rounded-pill"
            :class="{ 'not-empty': quantity > 0 }"
          >
            <a
              role="button"
              class="minus rounded-circle"
              @click.stop.prevent="
                minus(true, { infos: { ...selectedOptions } })
              "
              v-ripple
            >
              <v-icon>$minus</v-icon>
            </a>
            <div class="val-cnt">
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small">{{ quantityPerUnit }}</span>
            </div>
            <a
              role="button"
              class="plus rounded-circle"
              v-on:click.prevent="plus"
              v-ripple
            >
              <v-icon v-if="quantity > 0">$plus</v-icon>
            </a>
          </div>
          <a
            role="button"
            class="add-to-cart rounded-circle"
            v-on:click.prevent="
              () => {
                if (item.product.price || item.product.priceDisplay) {
                  addToCart();
                }
              }
            "
            :class="{
              disabled: !item.product.price && !item.product.priceDisplay
            }"
            v-ripple
          >
            <v-icon>$cart</v-icon>
          </a>
        </template>
        <template v-else>
          <span
            class="text-body-1 font-weight-bold promo--text text-left text-sm-center"
            >{{ $t("lists.productUnavailable") }}</span
          >
        </template>
      </v-col>
      <v-col cols="1" sm="1" class="d-flex align-center justify-center">
        <v-btn small icon color="promo" v-on:click.prevent="removeItem()">
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style scoped lang="scss">
.list-item {
  border-bottom: 1px solid var(--v-grey-lighten1);
  ::v-deep .cur-price {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
  ::v-deep.qty-wrap.not-empty {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 150px;
    }
  }

  ::v-deep .price-um,
  ::v-deep .old-price {
    text-align: center;
  }
  ::v-deep .gross-total {
    font-size: 18px;
    font-weight: 700;
  }
  .add-to-cart {
    margin-left: 20px;
    background-color: $primary;
    height: 45px;
    width: 45px;
    min-height: 45px;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    i {
      color: $white;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions } from "vuex";

export default {
  name: "ListItem",
  components: { ProductPrice, ProductPromo },
  props: ["item", "isActive"],
  // mixins: [productMixin],
  data() {
    return { selectedOptions: {} };
  },
  computed: {
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    },
    hasPromo(item) {
      return (
        item.product.priceStandardDisplay &&
        item.unitPrice !== item.product.priceStandardDisplay
      );
    }
  }
};
</script>
