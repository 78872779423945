<template>
  <v-container
    fluid
    class="list-detail d-flex flex-column scroll-container px-0 px-sm-auto"
    v-if="list"
  >
    <h1 v-if="list">{{ list.name }}</h1>

    <div
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
    >
      <v-row no-gutters v-if="$vuetify.breakpoint.smAndUp" class="my-10">
        <v-col cols="12" md="5">
          <strong>{{ $t("lists.header.product") }}</strong>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4" md="2" class="text-center">
          <strong>{{ $t("lists.header.unitPrice") }}</strong>
        </v-col>
        <v-col cols="1" />
        <!-- trick to center header with qty-wrap -->
        <v-col cols="1" class="text-center">
          <strong>{{ $t("lists.header.qty") }}</strong>
        </v-col>

        <v-col cols="1"></v-col>
      </v-row>

      <v-list v-if="list">
        <ListItem
          v-for="item in list.wishlistItems"
          v-bind:key="item.itemId"
          :item="item"
          :isActive="true"
          @removeItem="removeFromList"
          @update="updateList"
        ></ListItem>
        <ListItem
          v-for="item in list.wishlistInactiveItems"
          v-bind:key="item.itemId"
          :item="item"
          @removeItem="removeFromList"
          :isActive="false"
        ></ListItem>
      </v-list>
    </div>
    <v-card
      light
      depresses
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length == 0"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title flat>{{ $t("lists.noProducts") }}</v-card-title>
    </v-card>

    <v-row no-gutters justify="center" justify-sm="space-between" class="mt-6">
      <v-btn
        outlined
        color="primary"
        class="order-2 order-sm-1"
        x-large
        :block="$vuetify.breakpoint.xs"
        depressed
        :disabled="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        @click="emptyList()"
      >
        {{ $t("lists.button.emptyList") }}
      </v-btn>

      <v-btn
        x-large
        :block="$vuetify.breakpoint.xs"
        color="primary"
        class="order-1 order-sm-2 mb-6 mb-sm-0"
        depressed
        @click="addAllToCart"
      >
        <v-icon class="mr-0 mr-sm-2">$cart</v-icon>
        <span class="font-weight-bold">{{
          $t("lists.button.addAllToCart")
        }}</span>
      </v-btn>
    </v-row>

    <v-row no-gutters class="mt-5 justify-center justify-sm-start">
      <v-btn color="white" text plain v-bind:to="'/profile/lists'">
        <v-icon class="primary white--text rounded-lg mr-3">$prev</v-icon>
        <span
          class="primary--text text-caption text-none font-weight-bold"
          v-html="$t('lists.button.backToLists')"
        ></span>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import ListItem from "@/components/lists/ListItem";
import ListService from "~/service/listService";

import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions } from "vuex";
var _this;

export default {
  name: "List",
  data() {
    return {
      list: null
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive],
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    fetchList(listId) {
      ListService.getList(listId).then(list => {
        _this.list = list;
      });
    },
    async removeFromList({ itemId, product }) {
      let res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          _this.list,
          itemId,
          product.productId
        );
        _this.list = newList;
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${_this.list.name}?`;

      let res = await _this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        _this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await _this.addProductsFromWishlist(_this.list.listId);
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        _this.list.listId,
        item,
        newQuantity
      );
      _this.list = res;
    },
    reload() {
      this.fetchList(this.$route.params.listId);
    }
  },
  created() {
    _this = this;
    this.reload();
  }
};
</script>
